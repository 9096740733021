import { types } from '../types';

export const setOpenSnackbarState = (message) => ({
  type: types.SET_OPEN_SNACKBAR_STATE,
  payload: {
    message
  }
});

export const setCloseSnackbarState = () => ({
  type: types.SET_CLOSE_SNACKBAR_STATE
})