import { userApi } from '../api/user.api';

const auth = {
  isAuthenticated: false,
  async authenticate() {
    try {
      let result = await userApi.checkLogin();
      console.log('result authenticate', result);
      auth.isAuthenticated = true;
    } catch (error) {
      console.log('error authenticate', error);
      auth.isAuthenticated = false;
    }
  }
}

export default auth;