import { types } from '../types';

const initialState = {
  snackbarState: {
    open: false,
    vertical: 'top',
    horizontal: 'right',
    message: ''
  }
};

export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_OPEN_SNACKBAR_STATE:
      return {
        ...state,
        snackbarState: {
          open: true,
          vertical: 'top',
          horizontal: 'right',
          message: action.payload.message
        }
      }
    case types.SET_CLOSE_SNACKBAR_STATE:
      return {
        ...state,
        snackbarState: {
          open: false,
          vertical: 'top',
          horizontal: 'right',
          message: ''
        }
      }
    default: return state;
  }
}