import axiosConfig from '../config/axios';
import axios from 'axios';
import { paths } from '../constant/paths'

export const userApi = {};

userApi.login = (data) => {
  return axios({
    method: "POST",
    // url: process.env.REACT_APP_ENDPOINT + paths.login,
    url: "/api/" + paths.login,
    data
  });
}

userApi.checkLogin = async () => {
  return await axiosConfig({
    method: "GET",
    url: paths.get
  });
}

userApi.getList = async (params) => {
  return await axiosConfig({
    method: 'GET',
    url: paths.getList,
    params
  })
}

userApi.register = async (data) => {
  return await axiosConfig({
    method: 'POST',
    url: paths.register,
    data
  })
}

userApi.deleteUser = async id => {
  return await axiosConfig({
    method: 'DELETE',
    url: `users/${id}`,
  })
}

userApi.checkUsernameExist = async (data) => {
  return await axiosConfig({
    method: "POST",
    url: paths.checkUsernameExist,
    data
  })
}

userApi.getUserDetail = async (id) => {
  return await axiosConfig({
    method: "GET",
    url: `users/detail/${id}`
  })
}

userApi.edit = async (data) => {
  return await axiosConfig({
    method: "POST",
    url: paths.edit,
    data
  })
}

userApi.changePassword = async(data)=>{
  return await axiosConfig({
    method: "POST",
    url: `${paths.user}/changePassword`,
    data
  })
}

userApi.editProfile = async(data)=>{
  return await axiosConfig({
    method: "POST",
    url: `${paths.user}/editProfile`,
    data
  })
}

userApi.checkPassword = async(data)=>{
  return await axiosConfig({
    method:"POST",
    url: `${paths.user}/checkPassword`,
    data
  })
}