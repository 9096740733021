export const paths = {};

// User
paths.user = "users";
paths.login = "users/login";
paths.get = "users/get";
paths.getList = "users/getList";
paths.register = "users/register";
paths.checkUsernameExist = "users/checkUsernameExist";
paths.edit = "users/edit";
// paths.getUserDetail = "users/detail/:"

// Question
paths.createQuiz = "questions/createQuiz";

// Source
paths.source = "source";

// Category
paths.category = "category";

// Quiz
paths.quiz = "quiz";