import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import AppBar from './AppBar';

const useStyles = makeStyles((theme) => ({
  styleMain: {
    height: 'calc(100vh - 75px)',
  }
}))

export default function MainLayout(props) {
  const classes = useStyles();
  const { children } = props;

  return (
    <>
      <AppBar />
      <main className={classes.styleMain}>
        <Container maxWidth="lg" style={{ padding: '50px' }}>
          {children}
        </Container>
      </main>
    </>
  )
}