import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box, Container } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useHistory, NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/img/logo.svg';
import HomeIcon from '@material-ui/icons/Home';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  styleToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%'
  },
  styleAppBar: {
    height: '65px',
    backgroundColor: "#275183"
  },
  styleNav: {

  }
}));

export default function MenuAppBar() {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    history.push('/login');
  }

  const handleClickSetting = ()=>{
    history.push('/account-setting');
    handleClose();
  }

  return (
    <AppBar position="static" className={classes.styleAppBar}>
      <Container maxWidth="lg">
        <Toolbar className={classes.styleToolbar}>
          <Logo onClick={() => { history.push('/home-page') }} style={{ height: '55px', width: '120px', cursor: 'pointer' }} />
          <Box display="flex" alignItems="center" height={1}>
            <NavLink className='styleNavLink' to="/user-management" activeClassName="selected" >
              <Box><HomeIcon style={{ color: '#fff' }} /></Box>
              <Box style={{ color: '#fff', marginLeft: '15px' }} >User</Box>
            </NavLink>
            <NavLink className='styleNavLink' to="/manage-question" activeClassName="selected" >
              <Box><DataUsageIcon style={{ color: '#fff' }} /></Box>
              <Box style={{ color: '#fff', marginLeft: '15px' }}>Question</Box>
            </NavLink>
            <NavLink className='styleNavLink' to="/configuration" activeClassName="selected" >
              <Box><SettingsIcon style={{ color: '#fff' }} /></Box>
              <Box style={{ color: '#fff', marginLeft: '15px' }}>Configuration</Box>
            </NavLink>
          </Box>
          <div>
            <IconButton
              onClick={handleMenu}
            >
              <AccountCircle style={{ color: '#fff' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClickSetting}>Account Setting</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
