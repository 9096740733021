import './App.css';
import Routes from './Routes';
import SnackbarCustom from './common/SnackbarCustom';
import { store } from './store';
import { Provider } from 'react-redux';

function App() {
  return (
    <Provider store={store}>
      <Routes />
      <SnackbarCustom />
    </Provider>
  );
}

export default App;
