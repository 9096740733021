import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from './routers/ProtectedRoute';
import { Box, LinearProgress } from '@material-ui/core';
// import SnackbarCustom from './common/SnackbarCustom';

const Login = lazy(() => import('./components/Login'));
const CreateQuiz = lazy(() => import('./components/CreateQuiz'));
const UserManagement = lazy(() => import('./components/UserManagement'));
const QuestionManagement = lazy(() => import('./components/QuestionManagement'));
const EditQuiz = lazy(() => import('./components/EditQuiz'));
const Configuration = lazy (()=> import('./components/Configuration'));
const AccountSetting = lazy(()=>import("./components/AccountSetting"));

const Routes = () => {
  return (
    <Suspense
      fallback={(
        <Box width='100vw' height='100vh' textAlign='center' lineHeight='100vh'>
          <LinearProgress />
        </Box>
      )}
    >
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <ProtectedRoute exact path="/create-quiz" component={CreateQuiz} />
          <ProtectedRoute exact path="/edit-quiz/:id" component={EditQuiz} />
          <ProtectedRoute exact path="/manage-question" component={QuestionManagement} />
          <ProtectedRoute exact path="/user-management" component={UserManagement} />
          <ProtectedRoute exact path="/configuration" component={Configuration}/>
          <ProtectedRoute exact path="/account-setting" component={AccountSetting}/>
          <Redirect from="/" to="/user-management" />
        </Switch>
        {/* <SnackbarCustom /> */}
      </Router>
    </Suspense>
  )
}

export default Routes;