import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../utils/auth.js';
import MainLayout from '../components/MainLayout';
import { userApi } from '../api/user.api';

const ProtectedRoute = (
  {
    component: Component,
    ...rest
  }
) => {
  const [rendering, setRendering] = useState(false);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      (async function checkLogin() {
        try {
          let result = await userApi.checkLogin();
          console.log('result checkLogin', result);
          auth.isAuthenticated = true;
          setRendering(true);
        } catch (error) {
          auth.isAuthenticated = false;
          console.log('error checkLogin', error);
        }
      })()
    } else {
      setRendering(true);
    }
  }, []);

  return (
    rendering
      ?
      (<Route
        {...rest}
        render={props => {
          return auth.isAuthenticated ? (
            <MainLayout>
              <Component {...props} />
            </MainLayout>
          ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          )
        }
        }
      />)
      : null
  )
  // if (rendering) {
  //   return <Route
  //     {...rest}
  //     render={props => {
  //       return auth.isAuthenticated ? (
  //         <MainLayout>
  //           <Component {...props} />
  //         </MainLayout>
  //       ) : (
  //         <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  //       )
  //     }
  //     }
  //   />
  // } else {
  //   return null
  // }
};

export default ProtectedRoute;