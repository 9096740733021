import axios from 'axios';

// const axiosConfig = axios.create({
//   baseURL: process.env.REACT_APP_ENDPOINT
// });

const axiosConfig = axios.create({
  baseURL: "/api/"
})

axiosConfig.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
)

axiosConfig.interceptors.response.use(
  null,
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location = "/login";
    }
  }
)

export default axiosConfig;